import { Suspense, lazy, useEffect } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { useGTM, useClarity } from '@skywatch/ui'
import { Wait, NotFoundPage, EntrancePage, ErrorPage } from '@drive/shared'
import { ProtectedRoute, AuthRouter, useProfile } from '@drive/auth'
import { useBackButton, usePWAInstall } from '~/hooks'
import { Management } from '~/pages'

const { VITE_GTM_ID } = import.meta.env

const AcquisitionModule = lazy(() => import('@drive/acquisition'))
const OdometerModule = lazy(() => import('@drive/odometer'))
const BillingModule = lazy(() => import('@drive/billing'))
const PolicyModule = lazy(() => import('@drive/policy'))
const LandingModule = lazy(() => import('@drive/landing'))
const ProvidersModule = lazy(() => import('@drive/providers'))

const AppRouter = () => {
  const {
    state: { email },
  } = useProfile()
  const { initialize, sendEvent } = useGTM()
  const { initialize: initializeClarity, sendEvent: sendClarityEvent } = useClarity()

  // initialize the pwa install hook
  usePWAInstall()
  // register to navigation event
  useBackButton(() => {
    sendEvent("GIG_used_portal's_back")
  })

  // initialize GTM module
  useEffect(() => {
    initialize(VITE_GTM_ID)
    initializeClarity()
    if (email) sendClarityEvent(email)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Routes>
      <Route
        path=""
        element={
          <Suspense fallback={<Wait />}>
            <LandingModule />
          </Suspense>
        }
      />
      <Route
        path="insurance/*"
        element={
          <Suspense fallback={<Wait />}>
            <ProtectedRoute>
              <AcquisitionModule />
            </ProtectedRoute>
          </Suspense>
        }
      />
      <Route
        path="odometer/*"
        element={
          <Suspense fallback={<Wait />}>
            <ProtectedRoute>
              <OdometerModule />
            </ProtectedRoute>
          </Suspense>
        }
      />
      <Route path="entrance" element={<EntrancePage />} />
      <Route path="management/*" element={<Management />}>
        <Route
          path="policy/*"
          element={
            <Suspense fallback={<Wait />}>
              <ProtectedRoute>
                <PolicyModule />
              </ProtectedRoute>
            </Suspense>
          }
        />
        <Route
          path="billing/*"
          element={
            <Suspense fallback={<Wait />}>
              <ProtectedRoute>
                <BillingModule />
              </ProtectedRoute>
            </Suspense>
          }
        />
        <Route
          path="providers"
          element={
            <Suspense fallback={<Wait />}>
              <ProtectedRoute>
                <ProvidersModule />
              </ProtectedRoute>
            </Suspense>
          }
        />
      </Route>
      <Route path="auth/*" element={<AuthRouter isAdmin={false} />} />
      <Route path="error" element={<ErrorPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  )
}

export default () => (
  <BrowserRouter>
    <AppRouter />
  </BrowserRouter>
)
