import { FC, useEffect, useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import {
  Button,
  ClipboardIcon,
  Page,
  ReceiptCheckIcon,
  RoundedTabs,
  ShieldIcon,
  SpeedometerIcon,
  FilePlusIcon,
  UserCircleIcon,
} from '@skywatch/ui'
import { useProfile } from '@drive/auth'
import { Header, createFromApplicationFn, getRelaventApplicationId, Wait } from '@drive/shared'
import { isMobile } from 'react-device-detect'

const tabs = [
  { name: 'Policy', icon: <ClipboardIcon />, route: 'policy' },
  { name: 'Billing', icon: <ReceiptCheckIcon />, route: 'billing' },
  { name: 'GIG Account', icon: <UserCircleIcon />, route: 'providers' },
]

const mobileMenuItems = [
  { name: 'Policy management', icon: ShieldIcon, href: '/management/policy' },
  {
    name: 'Upload Odometer',
    href: '/odometer',
    icon: SpeedometerIcon,
  },
  {
    name: 'Billing',
    href: 'billing',
    icon: ReceiptCheckIcon,
  },
  {
    name: 'Get a new quote',
    href: '/insurance',
    icon: FilePlusIcon,
  },
]
const desktopMenuItems: { name: string; href: string; icon: FC }[] | undefined = []

export default () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [selectedTab, setSelectedTab] = useState(tabs.findIndex(t => location.pathname.split('/').includes(t.route)))
  const isPolicyTab = selectedTab === 0

  const { state: user } = useProfile()

  const handleOnClick = async () => {
    setLoading(true)
    const applicationId = await getRelaventApplicationId(user?.id)
    if (applicationId) await createFromApplicationFn(null, { pathParams: { applicationId: applicationId } })
    setLoading(false)
    navigate('/insurance')
  }

  useEffect(() => {
    const a = tabs.findIndex(t => location.pathname.split('/').includes(t.route))
    setSelectedTab(a)
  }, [location])

  return (
    <Page className="font-poppins flex h-screen touch-none flex-col bg-[#F2F2F2]">
      <Header
        menuItems={isMobile ? mobileMenuItems : desktopMenuItems}
        selected={1}
        userName={`${user?.firstName || ''} ${user?.lastName || ''}`}
        email={user?.email}
        logout={() => navigate('/auth/logout')}
      >
        <Button className={'px-3.5 py-2 text-sm'} buttonType={'primary'} onClick={handleOnClick} variant={'standard'}>
          Get a new quote
        </Button>
      </Header>
      <main className="container flex h-[calc(100dvh-60px)] flex-col overflow-auto md:mt-6">
        <div className="flex shrink grow flex-col bg-white py-6 md:grow-0 md:rounded-xl">
          <div>
            <RoundedTabs
              tabs={tabs}
              onChange={i => {
                setSelectedTab(i)
                navigate(tabs[i].route)
              }}
              defaultTab={selectedTab}
            />
            <div className="mx-4 mt-6 md:mx-auto md:max-w-4xl">
              <Outlet />
            </div>
          </div>
        </div>
        {isPolicyTab && (
          <div className="sticky bottom-0 bg-gray-50 px-3.5 py-3.5 md:hidden">
            <Button
              className={'w-full px-3.5 py-2 text-sm'}
              buttonType={'primary'}
              onClick={handleOnClick}
              variant={'standard'}
            >
              Get a new quote
            </Button>
          </div>
        )}
      </main>
      <Wait visible={loading} />
    </Page>
  )
}
